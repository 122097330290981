
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({})
export default class AboutPage extends Vue {
  snackbar = false;
  text = "My timeout is set to 2000";
  timeout = 2000;
  multiLine = true;

  learnMore() {
    this.$store.commit("showSnackbar", {
      content: "This is where I show information about the error",
      color: "error"
    });
    const learnMoreButton = document.getElementById("learn-more-btn");
    if (learnMoreButton) {
      learnMoreButton.remove();
    }
  }
}
